<script>
// import ImageViewer from "element-ui/packages/image/src/image-viewer.vue";
import { on, off } from "element-ui/src/utils/dom";
import { rafThrottle, isFirefox } from "element-ui/src/utils/util";
import { PopupManager } from "element-ui/src/utils/popup";
import Loader from "@/components/base/Loader";
const Mode = {
  CONTAIN: {
    name: "contain",
    icon: "el-icon-full-screen"
  },
  ORIGINAL: {
    name: "original",
    icon: "el-icon-c-scale-to-original"
  }
};

const mousewheelEventName = isFirefox() ? "DOMMouseScroll" : "mousewheel";

export default {
  // extends: ImageViewer
  components: { Loader },
  props: {
    urlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    onSwitch: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      index: this.initialIndex,
      isShow: false,
      infinite: true,
      loading: false,
      loadingService: undefined,
      mode: Mode.CONTAIN,
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      }
    };
  },
  computed: {
    isSingle() {
      return this.urlList.length <= 1;
    },
    isFirst() {
      return this.index === 0;
    },
    isLast() {
      return this.index === this.urlList.length - 1;
    },
    currentImg() {
      return this.urlList[this.index];
    },
    imgStyle() {
      const { scale, deg, offsetX, offsetY, enableTransition } = this.transform;
      const style = {
        transform: `scale(${scale}) rotate(${deg}deg)`,
        transition: enableTransition ? "transform .3s" : "",
        "margin-left": `${offsetX}px`,
        "margin-top": `${offsetY}px`
      };
      if (this.mode === Mode.CONTAIN) {
        style.maxWidth = style.maxHeight = "100%";
      }
      return style;
    },
    viewerZIndex() {
      const nextZIndex = PopupManager.nextZIndex();
      return this.zIndex > nextZIndex ? this.zIndex : nextZIndex;
    }
  },
  watch: {
    index: {
      handler: function(val) {
        console.log(this);
        this.reset();
        this.onSwitch(val);
      }
    },
    currentImg(val) {
      this.$nextTick(_ => {
        const $img = this.$refs[`img_${this.index}`];
        if (!$img.complete) {
          this.loadingService = this.$loading({
            target: $img.parentElement,
            body: false,
            fullscreen: false,
            background: "#000000d4",
            customClass: "el-image-viewer__loader"
          });
          this.loading = true;
        }
      });
    }
  },
  render(h) {
    return (
      <transition name="viewer-fade">
        <div
          tabindex="-1"
          ref="el-image-viewer__wrapper"
          class="el-image-viewer__wrapper"
          style={{ "z-index": this.viewerZIndex }}
        >
          <div
            class="el-image-viewer__mask"
            onClick={this.handleMaskClick}
          ></div>
          <span
            class="el-image-viewer__btn el-image-viewer__close"
            onClick={this.hide}
          >
            <i class="el-icon-close"></i>
          </span>
          {!this.isSingle && [
            <span
              class={{
                "el-image-viewer__btn": "el-image-viewer__btn",
                "el-image-viewer__prev": '"el-image-viewer__prev"',
                ...{ "is-disabled": !this.infinite && this.isFirst }
              }}
              onClick={this.prev}
            >
              <i class="el-icon-arrow-left" />
            </span>,
            <span
              class={{
                "el-image-viewer__btn": "el-image-viewer__btn",
                "el-image-viewer__next": '"el-image-viewer__next"',
                ...{ "is-disabled": !this.infinite && this.isLast }
              }}
              onClick={this.next}
            >
              <i class="el-icon-arrow-right" />
            </span>
          ]}
          <div class="el-image-viewer__btn el-image-viewer__actions">
            <div class="el-image-viewer__actions__inner">
              <i
                class="el-icon-zoom-out"
                onClick={() => this.handleActions("zoomOut")}
              ></i>
              <i
                class="el-icon-zoom-in"
                onClick={() => this.handleActions("zoomIn")}
              ></i>
              <i class="el-image-viewer__actions__divider"></i>
              {Boolean(this.$scopedSlots) && [
                <p class="el-image-viewer__title">
                  {this.$scopedSlots.details({ index: this.index })}
                </p>
              ]}
              <i class="el-image-viewer__actions__divider"></i>
              <i
                class="el-icon-refresh-left"
                onClick={() => this.handleActions("anticlocelise")}
              ></i>
              <i
                class="el-icon-refresh-right"
                onClick={() => this.handleActions("clocelise")}
              ></i>
            </div>
          </div>
          <div class="el-image-viewer__canvas" ref="el-image-viewer__canvas">
            {
              <img
                ref={`img_${this.index}`}
                class="el-image-viewer__img"
                src={this.currentImg}
                style={this.imgStyle}
                onLoad={this.handleImgLoad}
                onError={this.handleImgError}
                onMousedown={this.handleMouseDown}
              />
            }
          </div>
        </div>
      </transition>
    );
  },
  methods: {
    hide() {
      this.deviceSupportUninstall();
      this.onClose();
    },
    deviceSupportInstall() {
      this._keyDownHandler = e => {
        e.stopPropagation();
        const keyCode = e.keyCode;
        switch (keyCode) {
          // ESC
          case 27:
            this.hide();
            break;
          // SPACE
          case 32:
            this.toggleMode();
            break;
          // LEFT_ARROW
          case 37:
            this.prev();
            break;
          // UP_ARROW
          case 38:
            this.handleActions("zoomIn");
            break;
          // RIGHT_ARROW
          case 39:
            this.next();
            break;
          // DOWN_ARROW
          case 40:
            this.handleActions("zoomOut");
            break;
        }
      };
      this._mouseWheelHandler = rafThrottle(e => {
        const delta = e.wheelDelta ? e.wheelDelta : -e.detail;
        if (delta > 0) {
          this.handleActions("zoomIn", {
            zoomRate: 0.015,
            enableTransition: false
          });
        } else {
          this.handleActions("zoomOut", {
            zoomRate: 0.015,
            enableTransition: false
          });
        }
      });
      on(document, "keydown", this._keyDownHandler);
      on(document, mousewheelEventName, this._mouseWheelHandler);
    },
    deviceSupportUninstall() {
      off(document, "keydown", this._keyDownHandler);
      off(document, mousewheelEventName, this._mouseWheelHandler);
      this._keyDownHandler = null;
      this._mouseWheelHandler = null;
    },
    handleImgLoad(e) {
      if (this.loadingService) {
        this.loadingService.close();
      }
      this.loading = false;
    },
    handleImgError(e) {
      if (this.loadingService) {
        this.loadingService.close();
      }
      this.loading = false;
      e.target.alt = "Image";
    },
    handleMouseDown(e) {
      if (this.loading || e.button !== 0) return;

      const { offsetX, offsetY } = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;
      this._dragHandler = rafThrottle(ev => {
        this.transform.offsetX = offsetX + ev.pageX - startX;
        this.transform.offsetY = offsetY + ev.pageY - startY;
      });
      on(document, "mousemove", this._dragHandler);
      on(document, "mouseup", ev => {
        off(document, "mousemove", this._dragHandler);
      });

      e.preventDefault();
    },
    handleMaskClick() {
      if (this.maskClosable) {
        this.hide();
      }
    },
    reset() {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      };
    },
    toggleMode() {
      if (this.loading) return;
      const modeNames = Object.keys(Mode);
      const modeValues = Object.values(Mode);
      const index = modeValues.indexOf(this.mode);
      const nextIndex = (index + 1) % modeNames.length;
      this.mode = Mode[modeNames[nextIndex]];
      this.reset();
    },
    prev() {
      if (this.isFirst && !this.infinite) return;
      const len = this.urlList.length;
      this.index = (this.index - 1 + len) % len;
    },
    next() {
      if (this.isLast && !this.infinite) return;
      const len = this.urlList.length;
      this.index = (this.index + 1) % len;
    },
    handleActions(action, options = {}) {
      if (this.loading) return;
      const { zoomRate, rotateDeg, enableTransition } = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options
      };
      const { transform } = this;
      switch (action) {
        case "zoomOut":
          if (transform.scale > 0.2) {
            transform.scale = parseFloat(
              (transform.scale - zoomRate).toFixed(3)
            );
          }
          break;
        case "zoomIn":
          transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3));
          break;
        case "clocelise":
          transform.deg += rotateDeg;
          break;
        case "anticlocelise":
          transform.deg -= rotateDeg;
          break;
      }
      transform.enableTransition = enableTransition;
    }
  },
  mounted() {
    this.deviceSupportInstall();
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    // add tabindex then wrapper can be focusable via Javascript
    // focus wrapper so arrow key can't cause inner scroll behavior underneath
    this.$refs["el-image-viewer__wrapper"].focus();
  },
  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-image-viewer__wrapper {
  .el-image-viewer__mask {
    opacity: 1;
  }
  .el-image-viewer__actions {
    width: 507px;
    .el-image-viewer__title {
      @include normal-text;
    }
  }
  .el-image-viewer__canvas::v-deep {
    .el-image-viewer__loader {
      .el-loading-spinner .path {
        stroke: white;
      }
    }
  }
}
</style>
